/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, Divider, getEsgianTheme, Modal } from "@esgian/esgianui";

import { api } from "../../../api/api";
import { getErrorMessage } from "../../../helper/errorHandling";
import { useSelector } from "../../../hooks/use-selector";
import { getThemeMode } from "../../../store/selector/common";
import { GenericType, MESSAGE, THEME, ThemeModeEnum } from "../../../types";
import { Loading } from "../../Loading";
import DropDownList from "../DropDownList";
import Paragraph from "../Paragraph";

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);
  return {
    modalContainer: {
      "& .MuiDialog-container": {
        alignItems: "start",
        position: "absolute",
        right: 0,
        height: "auto",
        width: "22.5rem",
      },
      "& .MuiBackdrop-root": {
        opacity: "0 !important",
      },
      "& .MuiPaper-root": {
        margin: 0,
        width: 1,
        height: "100vh",
        borderRadius: 0,
        backgroundColor:
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.black
            : theme.palette.common.white,
        backgroundImage: "none",
      },
      "& .MuiDialogContent-root": {
        padding: 0,
      },
      "& .MuiDialogTitle-root": {
        width: "94%",
      },
    },
    divider: {
      my: 3,
    },
    contentBox: {
      py: 3,
      px: 2,
    },
  };
};

type Prop = {
  id?: number | null;
  onClose: () => void;
  onLeaseRoundClick: (id: number) => void;
};

export const LeaseRoundDrawer: FC<Prop> = ({ id, onClose }) => {
  const themeMode = useSelector(getThemeMode);
  const classes = useStyles(themeMode);

  const [data, setData] = useState<GenericType | null>(null);

  const fetchLeaseRoundData = async () => {
    try {
      const { data: res } = await api.lease.getLeaseRound(id);
      const leaseAreas = res?.leaseRegion?.map((l: GenericType) => ({
        name: l.name,
        estimatedCapacity: l?.windProject?.capacity,
        projectType: l?.windProject?.projectType?.type,
        projectStatus: l?.windProject?.projectStatus?.status,
        operationDate: l?.windProject?.operationDate,
      }));
      const bids = res?.bids?.map((b: GenericType) => ({
        id: b.id,
        name: b?.name,
        developers: b?.developers
          ?.map(
            (dev: GenericType) =>
              `${dev.companyShortName} ${dev?.ownershipPercentage > 0 ? dev?.ownershipPercentage + "%" : ""}`,
          )
          .join(", "),
        status: b?.status?.status,
        capacity: b?.capacity,
      }));
      setData({
        keyInfo: {
          leaseRoundName: res?.leaseRoundName,
          leaseRoundStatus: res?.leaseRoundStatus,
          leaseRoundCapacityTgtMW: res?.leaseRoundCapacityTgtMW,
          leaseRoundType: res?.leaseRoundType.type,
          leaseAwardDate: res?.leaseAwardDate,
          leaseRoundOpen: res?.leaseRoundOpen,
          leaseRoundClose: res?.leaseRoundClose,
        },
        leaseAreas,
        bids,
      });
    } catch (err) {
      toast.error(`${MESSAGE.LeaseRound}: ${getErrorMessage(err)}`);
    }
  };

  const handleClose = () => {
    return onClose();
  };

  useEffect(() => {
    setData(null);
    if (id) {
      fetchLeaseRoundData();
    }
  }, [id]);

  return (
    <Modal
      sx={classes.modalContainer}
      open={!!id}
      size="sm"
      key={id}
      title={data ? data.keyInfo.leaseRoundName : "Lease Round"}
      handleClose={handleClose}
    >
      {data ? (
        <Box sx={classes.contentBox}>
          <Paragraph
            title="Basic Info"
            properties={{
              leaseRoundName: { key: "Lease round name" },
              leaseRoundStatus: { key: "Lease round status" },
              leaseRoundCapacityTgtMW: { key: "Target MW" },
              leaseRoundType: { key: "Lease area type" },
              leaseAwardDate: { key: "Award date", type: "date" },
              leaseRoundOpen: { key: "Opens for bids", type: "date" },
              leaseRoundClose: { key: "Closes for bids", type: "date" },
            }}
            data={data.keyInfo}
          />

          {data?.leaseAreas?.length ? (
            <>
              <Divider sx={classes.divider} />
              <DropDownList
                title="Lease Area"
                subTitleKey="name"
                name="leaseAreas"
                data={data?.leaseAreas}
                defaultExpand={true}
                properties={{
                  estimatedCapacity: { key: "Estimated Capacity", unit: "MW" },
                  projectType: { key: "Project Type" },
                  projectStatus: { key: "Project Status" },
                  operationDate: { key: "Operation date", type: "date" },
                }}
              />
            </>
          ) : null}
          <Divider sx={classes.divider} />
          {data.bids.length ? (
            <DropDownList
              title="Bids"
              subTitleKey="name"
              name="bids"
              data={data.bids}
              defaultExpand={true}
              subType={{
                data: { projectNumber: data.bids.length },
                prop: { projectNumber: { key: "Bids number" } },
              }}
              properties={{
                name: { key: "Bid name" },
                developers: { key: "Developers" },
                status: { key: "Bid status" },
                capacity: { key: "Bid Capacity", unit: "MW" },
              }}
            />
          ) : null}
        </Box>
      ) : (
        <Loading />
      )}
    </Modal>
  );
};
